import React from "react";
import { CiInstagram } from "react-icons/ci";
import data from "../../data.json";
import { useNavigate } from "react-router-dom";

const Footer = ({ footerRef }) => {
  const navigate = useNavigate();
  const blogItems = data?.blogItems;
  return (
    <>
      <footer className="flex justify-center items-center pt-10 w-full border-t" ref={footerRef} >
        <div className="grid lg:grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-1 gap-10 max-sm:px-10 sm:w-3/4 xl:w-3/5">
          <div className="">
           <div className="">
            <h1 className="text-2xl font-bold text-[#0D0E10]">İletişim</h1>
              <div className="flex flex-col gap-5">
                <a
                  href="mailto:info@ellusion.tech"
                  className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500"
                >
                  info@ellusion.tech
                </a>
                <a
                  href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwj5qZ6X746KAxUWA9sEHQApFvIQ4kB6BAgqEAM&url=%2Fmaps%2Fplace%2F%2Fdata%3D!4m2!3m1!1s0x14caa37b7c105081%3A0x2fa6c647ba2e494b%3Fsa%3DX%26ved%3D1t%3A8290%26ictx%3D111&usg=AOvVaw3tLtUBq5rEjxSsDdaAVCfN&opi=89978449"
                  className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500"
                >
                  Beşyol, İnönü Cd. No:38, 34295, 34000 Küçükçekmece/İstanbul
                </a>
              </div>
           </div>
           <div className="flex gap-5 mt-4">
            <CiInstagram size={32} className="hover:text-[#4d7aff] hover:ms-1 transition-all duration-300 cursor-pointer" onClick={() => window.location.href = "https://www.instagram.com/ellusiontech/"} />
           </div>
          </div>
          <div className="">
            <h1 className="text-2xl font-bold text-[#0D0E10]">
              Kullanışlı Linkler
            </h1>
            <div className="flex flex-col gap-5 ">
              <a
                href="/x"
                className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500 mt-3"
              >
                Anasayfa
              </a>
              <a
                href="/bloglar"
                className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500"
              >
                Bloglar
              </a>
              <a
                href="/markalar"
                className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500"
              >
                Markalar
              </a>
            </div>
          </div>
          <div className="">
            <h1 className="text-2xl font-bold text-[#0D0E10]">
              Son Eklenen Bloglar
            </h1>
            <div className="flex flex-col gap-5">
              {blogItems?.slice(-4)?.map((item) => (
                <a
                  href={`/blog/${item?.slug}`}
                  className="hover:text-[#4d7aff] hover:ps-2 transition-all duration-500 line-clamp-2"
                >
                  {item?.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>

      <section className="border-t text-center mt-10 pt-5 pb-2 text-[#070707] flex flex-col items-center gap-3">
        <img src="/images/logo.png" alt="ellusion-logo" className="w-96 max-sm:w-52" />
        <p>© 2021 Ellusion. Tüm hakları saklıdır.</p>
      </section>
    </>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import BarPoll from "../layouts/BarPool";
import data from "../../data.json";
import { useParams } from "react-router-dom";

const BlogDetails = ({ footerRef }) => {
  const { slug } = useParams();
  const stickyRef = useRef();
  const lastBlogs = useRef();
  const [isAtFooter, setIsAtFooter] = useState(false);
  const [blogData, setBlogData] = useState({});
  const otherBlogs = data?.blogItems?.filter((blog) => blog.slug !== slug).slice(-4).reverse();

  useEffect(() => {
    setBlogData(data?.blogItems?.find((blog) => blog.slug === slug));

    const handleScroll = () => {
      const sidebarRect = stickyRef.current.getBoundingClientRect();
      const footerRect = footerRef.current.getBoundingClientRect();
      const blogsRect = lastBlogs.current.getBoundingClientRect();

      setIsAtFooter(blogsRect.top <= sidebarRect.height);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [data]);

  return (
    <>
      <div className="flex justify-center max-md:px-8 md:px-10 lg:px-20 xl:px-32 mt-32">
        <div className="flex max-md:flex-col gap-5">
          <div className="flex flex-col items-center md:gap-5 md:w-3/4">
            <div className="mb-5 w-full text-center">
              <h1 className="font-bold max-sm:text-xl max-md:text-2xl text-3xl mb-3">
                {blogData?.title}
              </h1>
              <img
                src={blogData?.image}
                alt="blog_img"
                className="w-full rounded-2xl"
              />
            </div>
            <div className="sm:px-5 text-center">
              <div className="flex justify-center gap-10">
                <p>
                  <b>Yayın Tarihi:</b> {blogData?.createdAt}
                </p>
                <p>
                  <b>Güncelleme Tarihi:</b> {blogData?.updatedAt}
                </p>
              </div>
              <h3 className="max-sm:text-lg text-xl mt-5">
                {blogData?.subtitle}
              </h3>
              <div className="flex flex-col  justify-center">
                {blogData?.sections?.map((section) => (
                  <div className="mb-10">
                    <h1 className="max-sm:text-lg text-xl">{section.title}</h1>
                    <p
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    ></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`sticky md:w-1/4 ${
              isAtFooter ? " bottom-0 top-0 " : "top-[180px]"
            } max-h-[80vh] overflow-y-auto`}
            ref={stickyRef}
          >
            <BarPoll />
          </div>
        </div>
      </div>
      <div className="max-md:px-8 md:px-10 lg:px-20 xl:px-32 mb-10" ref={lastBlogs}>
        <div className="">
          <h1 className="font-bold text-2xl">Son Eklenen Bloglar</h1>
          <div className="grid grid-cols-4 gap-5">
            {otherBlogs?.map((blog) => (
              <div className="cursor-pointer" onClick={() => window.location.replace(`/blog/${blog.slug}`)}>
              <img src={blog.image} alt="" />
              <h1 className="text-xl font-bold">{blog.title}</h1>
              <p className="blog-card-desc">{blog?.subtitle}</p>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;

import React from "react";
import MetaData from "../layouts/MetaData";

const Brands = () => {
  const data = [
    {
      img: "/images/navision-logo.png",
      title: "Navision      ",
      url: "/yakinda-sizlerle",
      text: "ReactJS, modern kullanıcı arayüzleri geliştirmek için kullanılan güçlü ve esnek bir JavaScript kütüphanesidir. Bileşen tabanlı yapısı sayesinde kodunuzu modüler ve tekrar kullanılabilir hale getirirken, sanal DOM özelliği ile yüksek performans sağlar. ReactJS, geliştiricilere kullanıcı deneyimini üst düzeye çıkaracak dinamik ve interaktif web uygulamaları oluşturma imkanı sunar.",
    },
    {
      img: "/images/ellusion-create-logo.png",
      title: "ellusion Creative",
      url: "https://",
      text: "Node.js, JavaScript’i sunucu tarafında çalıştırmayı mümkün kılan güçlü ve hızlı bir platformdur. Olay tabanlı ve asenkron yapısı sayesinde yüksek performans gerektiren uygulamalar için idealdir. Node.js, geniş ekosistemi ve NPM desteği ile geliştiricilere ölçeklenebilir, hızlı ve güvenilir sunucu uygulamaları oluşturma imkanı sunar.",
    },
    {
      img: "/images/ellu-agency-logo.png",
      title: "PostAI",
      url: "/yakinda-sizlerle",
      text: "MongoDB, modern uygulamalar için tasarlanmış, belge tabanlı bir NoSQL veritabanıdır. Esnek veri yapısı, hiyerarşik ilişkileri ve karmaşık veri türlerini kolayca yönetmenize olanak tanır. Yüksek performansı ve ölçeklenebilirliği ile MongoDB, geliştiricilere güvenilir ve esnek veri yönetimi sağlar.",
    },
    {
      img: "/images/agency-logo.png",
      title: "ellucation",
      url: "/yakinda-sizlerle",
      text: "MongoDB, modern uygulamalar için tasarlanmış, belge tabanlı bir NoSQL veritabanıdır. Esnek veri yapısı, hiyerarşik ilişkileri ve karmaşık veri türlerini kolayca yönetmenize olanak tanır. Yüksek performansı ve ölçeklenebilirliği ile MongoDB, geliştiricilere güvenilir ve esnek veri yönetimi sağlar.",
    },
  ];
  return (
    <div className="">
      <MetaData title="Markalar" description="Markalar sayfası" />
      <div className="relative">
        <img
          src="/images/brands-bg.png"
          alt=""
          className="w-full max-sm:h-screen sm:h-[700px]"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-center text-white">
            <h1 className="text-4xl font-semibold">Markalar</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              molestias tenetur repellendus consequatur provident neque atque
              eum eius, tempore debitis eos culpa ducimus corrupti quas iusto
              incidunt. Deserunt earum neque aspernatur quos, vel error odit
              doloremque iste sed, incidunt hic.
            </p>
          </div>
        </div>
      </div>

      <div className="px-32 max-sm:px-10">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-5">
          {data?.map((item, index) => (
            <div className="grid grid-cols-3 my-10 rounded border p-1 px-3 shadow">
              {((index + 1) % 2) / 2 !== 0 && (
                <div className="max-sm:col-span-3 col-span-1 max-md:hidden">
                  <img
                    src={item?.img}
                    alt=""
                    style={{filter: "drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5))"}}
                    className="w-full min-h-72 max-h-72"
                  />
                </div>
              )}
              <div className="max-sm:col-span-3 col-span-1 md:hidden">
                <img src={item?.img} alt="" className="w-full min-h-72 max-h-72" style={{filter: "drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5))"}} />
              </div>
              <div className="max-sm:col-span-3 col-span-2">
                <div className="flex flex-col justify-center h-full max-sm:items-center">
                  <h1 className="text-4xl font-bold text-center">{item?.title}</h1>
                  <p className="">{item?.text}</p>
                  <a href={item?.url} className="bg-[#0E38B1] hover:bg-[#3553ad] transition-all text-center text-white py-3 px-10 rounded-lg group cursor-pointer w-52 mt-5 max-sm:mb-3" >
                    Sayfaya Git
                  </a>
                </div>
              </div>
              {((index + 1) % 2) / 2 === 0 && (
                <div className="max-sm:col-span-3 col-span-1 max-md:hidden">
                  <img
                    src={item?.img}
                    alt=""
                    style={{filter: "drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5))"}}
                    className="w-full min-h-72 max-h-72"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
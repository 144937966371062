import React, { useEffect, useState } from "react";
import Reveal from "./Reveal";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ServicesArea = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cardItems = [
    {
      id: 1,
      title: "Create (Yarat)",
      img: "/images/edit-tools2.png",
      desc: "Ellusion, yenilikçi fikirleri hayata geçirerek sıfırdan projeler oluşturur ve onları gerçeğe dönüştürür.",
      className: "fade-right"
    },
    {
      id: 2,
      title: "Elevate (Yükselt)",
      img: "/images/revolution2.png",
      desc: "Projeleri sadece başlatmakla kalmaz, onların potansiyelini en üst seviyeye çıkarmak için stratejik bir şekilde büyütür ve geliştirir.",
      className: "fade-left"
    },
    {
      id: 3,
      title: "Dominate (Hakim Ol)",
      img: "/images/rise2.png",
      desc: "Oluşturulan projelerin, kendi alanlarında lider konuma gelmeleri ve kalıcı bir etki yaratmaları için çalışır.",
      className: "fade-right"
    },
  ];

  useEffect(() => {
    gsap.fromTo(
      ".services-text-left",
      { opacity: 0, x: -200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".services-text-left",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    gsap.fromTo(
      ".services-text-right",
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: ".services-text-right",
          start: "top 80%",
          end: "bottom 30%",
          toggleActions: "play none none reverse",
        },
      }
    );
    setSelectedCard(2);
  }, []);

  const Card = ({ isSelected, onClick, item }) => {
    return (
      <div
        className={`servicesCard cursor-pointer rounded-xl flex flex-col items-center justify-center transition-all max-md:h-96 max-h-screen h-full w-full px-2 ${item?.className} ${isSelected === item?.id ? "hover" : ""
          }`}
        onClick={onClick}
      >
        <div className="bg-[#4C40F7] px-10 py-10 flex justify-center items-center rounded-full mt-5">
          <img src={item.img} alt="" className={` ${item?.className} max-h-32`} />
        </div>
        <h1 className={`mt-5 font-bold text-2xl text-white ${item?.className}`}>{item.title}</h1>
        <p className="sm:mt-10 sm:px-12 sm:pb-10 sm:text-center text-[#d1cfcf] ">
          {item.desc}
        </p>
      </div>
    );
  };
  return (
    <Reveal>
      <div className="bg-[#0E38B1] max-md:px-3 md:px-12 xl:px-16 py-16 text-center">
        <div className="mb-10">
          <h1 className="text-4xl font-bold text-white mb-2 services-text-right">
            Fikirden Liderliğe: Ellusion’un Yolculuğu
          </h1>
          <p className="text-[#d1cfcf] services-text-left">
            Ellusion, sadece yenilikçi projeler oluşturmakla kalmaz, onları stratejik olarak büyütür ve sektörlerinde liderlik seviyesine taşır. Bu süreç, fikirlerin gerçeğe dönüşmesinden, projelerin potansiyellerini aşarak kalıcı bir etki yaratmalarına kadar uzanan bütüncül bir yaklaşıma dayanır. Yaratıcılığı, stratejiyi ve mükemmelliği bir araya getirerek projeleri zirveye taşıyoruz.
          </p>
        </div>
        <div className="grid max-sm:grid-cols-1 grid-cols-3 gap-5 xl:px-32">
          {cardItems?.map((i) => (
            <div className="" onMouseMove={() => setSelectedCard(i.id)}>
              <Card item={i} key={i.id} isSelected={selectedCard} />
            </div>
          ))}
        </div>
      </div>
    </Reveal>
  );
};

export default ServicesArea;

import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import MetaData from "../layouts/MetaData";

const Login = () => {
  const [data, setData] = useState({});
  const [loading, setLoding] = useState(false);
  const [location, setLocation] = useState("");

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onClick = () => {
    setLoding(true);
    setTimeout(() => {
      toast.error("Kayıtlı hesap bulunamadı");
      setLoding(false);
    }, 2500);
  };
  useEffect(() => {
    if (window.location.search.split("=")[1] === "navison") {
      setLocation("navision'a");
    } else if (window.location.search.split("=")[1] === "postai") {
      setLocation("PostAI'a");
    } else if (window.location.search.split("=")[1] === "ellucation") {
      setLocation("ellucation'a");
    } else {
      setLocation("navision'a");
    }
  }, []);

  return (
    <div className="flex justify-center items-center flex-col max-sm:h-[60vh] sm:h-[70vh] max-sm:px-10">
      <MetaData title="Giriş Yap" description={"Giriş Yap"} />
      <div className="max-w-[400px] w-full">
        <div className="flex justify-center items-center">
          <h1 className="text-4xl text-center font-bold mb-10 w-1/2">
            {location} Giriş Yap.
          </h1>
        </div>
        <div className="mb-3 flex flex-col">
          <label htmlFor="email">E-Posta:</label>
          <input
            type="email"
            name="email"
            onChange={onChange}
            className="border p-2 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#007AFF]"
            placeholder="ornek@gmail.com"
          />
        </div>
        <div className="mb-5 flex flex-col">
          <label htmlFor="password">Parola:</label>
          <input
            type="password"
            name="password"
            onChange={onChange}
            className="border p-2 rounded-lg"
            placeholder="sifre giriniz"
          />
        </div>
        <div className="mb-3">
          <button
            className="bg-[#007AFF] text-white rounded-lg w-full py-3"
            onClick={() => onClick()}
          >
            {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
          </button>
        </div>
        <div className="mb-3 flex justify-center gap-2">
          <p className="text-[#555555]">Şifreni mi unuttun ?</p>
          <span className="text-[#007AFF] cursor-pointer">Tıkla</span>
        </div>
      </div>
    </div>
  );
};

export default Login;

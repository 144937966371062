import React from "react";
import MetaData from '../layouts/MetaData';

const OurTechonology = () => {
  const data = [
    {
      img: "/images/react-logo.png",
      title: "React JS",
      text: "ReactJS, modern kullanıcı arayüzleri geliştirmek için kullanılan güçlü ve esnek bir JavaScript kütüphanesidir. Bileşen tabanlı yapısı sayesinde kodunuzu modüler ve tekrar kullanılabilir hale getirirken, sanal DOM özelliği ile yüksek performans sağlar. ReactJS, geliştiricilere kullanıcı deneyimini üst düzeye çıkaracak dinamik ve interaktif web uygulamaları oluşturma imkanı sunar.",
      left: true,
    },
    {
      img: "/images/nodejs-logo.png",
      title: "Node JS",
      text: "Node.js, JavaScript’i sunucu tarafında çalıştırmayı mümkün kılan güçlü ve hızlı bir platformdur. Olay tabanlı ve asenkron yapısı sayesinde yüksek performans gerektiren uygulamalar için idealdir. Node.js, geniş ekosistemi ve NPM desteği ile geliştiricilere ölçeklenebilir, hızlı ve güvenilir sunucu uygulamaları oluşturma imkanı sunar.",
      left: false,
    },
    {
      img: "/images/mongodb.png",
      title: "MongoDB",
      text: "MongoDB, modern uygulamalar için tasarlanmış, belge tabanlı bir NoSQL veritabanıdır. Esnek veri yapısı, hiyerarşik ilişkileri ve karmaşık veri türlerini kolayca yönetmenize olanak tanır. Yüksek performansı ve ölçeklenebilirliği ile MongoDB, geliştiricilere güvenilir ve esnek veri yönetimi sağlar.",
      left: true,
    },
  ];
  return (
    <div className="max-sm:px-10 px-32 flex justify-center flex-col">
      <MetaData title="Teknolojilerimiz" description={"Teknolojilerimiz"} />
      <div className="mb-5">
        <h1 className="text-4xl font-bold text-center">
          Projelerimizde Kullandığımız Teknolojiler
        </h1>
      </div>
      {data?.map((item) => (
        <div className="flex max-sm:flex-col my-10 gap-10">
          <div className="flex-2 sm:hidden">
            <img src={item?.img} className="w-full sm:min-w-96" alt="" />
          </div>
          {item?.left && (
            <div className="flex-2 max-sm:hidden">
              <img
                src={item?.img}
                className="w-full min-w-96 max-w-[500px] max-sm:h-24"
                alt=""
              />
            </div>
          )}
          <div className="">
            <h1
              className={`${
                item?.left
                  ? "max-sm:text-center text-right"
                  : "max-sm:text-center text-left"
              } max-sm:text-3xl sm:text-4xl font-bold`}
            >
              {item?.title}
            </h1>
            <p
              className={`${
                item?.left
                  ? "max-sm:text-center text-right"
                  : "max-sm:text-center text-left"
              }`}
            >
              {item?.text}
            </p>
          </div>
          {!item?.left && (
            <div className="flex-2 max-sm:hidden">
              <img
                src={item?.img}
                className="w-full min-w-96 max-w-[500px]"
                alt=""
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OurTechonology;

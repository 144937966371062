import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import MetaData from "../layouts/MetaData";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#E7EAF3]">
      <MetaData title="Sayfa Bulunamadı" description={"Sayfa Bulunamadı"} />
      <img
        src="/images/error.png"
        alt=""
        style={{ filter: "drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5))" }}
      />
      <h1 className="text-4xl text-center mt-10">
        <b>404</b>Sayfa Bulunamadı
      </h1>
      <p>Aradığınız sayfa bulunamadı.</p>
      <a href="/" className="bg-[#0E38B1] text-white py-3 px-10 rounded mt-5 shadow">
        <div className="flex gap-3 items-center">
          <p>Anasayfa</p>
          <FaLongArrowAltRight size={20} />
        </div>
      </a>
    </div>
  );
};

export default NotFound;

import React from 'react'
import { FaTools } from 'react-icons/fa'
import MetaData from '../layouts/MetaData';

const ComingSoon = () => {
  return (
    <div className='h-screen flex justify-center items-center flex-col px-32 max-sm:px-10 bg-[#E7EAF3]'>
      <MetaData title='Bakım Aşamasında' description="Bakım aşamasında sayfası" />
      <img src="/images/500.png" alt="error-503" className='h-[400px]' style={{ filter: "drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.5))" }} />
        <h1 className='text-4xl mt-5 font-bold mb-2'>Bakım Aşamasında</h1>
        <p className='text-center'>Şu anda bu sayfa üzerinde yoğun bir şekilde çalışmalarımız devam etmektedir. En iyi kullanıcı deneyimini sağlamak ve sizlere daha kaliteli bir hizmet sunabilmek adına özenle hazırlıyoruz. En kısa sürede sayfamız hizmetinize açılacaktır. Gösterdiğiniz sabır ve anlayış için teşekkür ederiz.</p>
        {/* <div className="border rounded-full p-10 mt-10">
            <FaTools size={64} color='gray' />
        </div> */}
    </div>
  )
}

export default ComingSoon
import React, { useEffect, useRef } from "react";
import SliderComp from "../layouts/Slider";
import Blog3 from "../layouts/Blog3";
import ParallaxContainer from "../layouts/ParallaxContainer";
import ServicesArea from "../layouts/ServicesArea";
import CounterArea from "../layouts/CounterArea";
import Section from "../layouts/Section";
import Reveal from "../layouts/Reveal";
import Section2 from "../layouts/Section2";
import OurLanguage from "../layouts/OurLanguage";
import LogoSlider from "../layouts/LogoSlider";
import MetaData from '../layouts/MetaData';

const Home = ({ textLeave, textEnter }) => {
  return (
    <>
    <MetaData title="Anasayfa" description={"Anasaya"} />
      <div className="overflow-hidden">
        <SliderComp />
      </div>
      {/* <LogoSlider /> */}
      <div className="font-noto overflow-hidden">
        <ServicesArea />

        <Section2 />
        <Section />

        <div className="">
          <Reveal>
            <ParallaxContainer />
          </Reveal>
          <Reveal>
            <CounterArea />
          </Reveal>
        </div>

        <OurLanguage />

        <div className="max-sm:px-3 max-lg:px-10 px-28 mt-16">
          <Reveal>
            <Blog3 />
          </Reveal>
        </div>

        {/* SIKÇA SORULANLAR */}
        {/* <div className="max-sm:px-3 max-lg:px-10 ps-10 xl:ps-28 mt-16">
          <Reveal>
            <FAQ />
          </Reveal>
        </div> */}
      </div>
    </>
  );
};

export default Home;
